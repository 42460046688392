<template>
  <v-container>
    <v-col cols="12" sm="12" md="12" lg="12">
      <base-material-card color="primary" icon="mdi-receipt-text-check-outline">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="create">
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="agencyId" :items="agencies" :label="$t('invoicing-page.agency')" required
                    item-text="agencyName"
                    item-value="agencyId" @input="prepareInvoiceData" prepend-icon="mdi-domain">
                  </v-autocomplete>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select v-model="langName" :items="invoiceLangs" :label="$t('invoicing-page.language')" required
                            item-text="langFullName" item-value="langName" prepend-icon="mdi-translate">
                  </v-select>
                  <span class="red--text font-weight-light">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" lg="6">
                <v-switch v-model="isPeriodRequired" inset :label="$t('invoicing-page.calculate-period')"></v-switch>
              </v-col>
            </v-row>

            <v-row v-if="isPeriodRequired">
              <v-col cols="12" sm="6" md="4">
                <v-dialog ref="dialog" v-model="modal" :return-value.sync="dateRange" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="dateRangeText"
                        :label="$t('invoicing-page.select-invoicing-range')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </template>
                  <v-date-picker :first-day-of-week="datePickerProps.firstDayOfWeek" v-model="dateRange" scrollable
                                 range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      {{$t('buttons.cancel')}}
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(dateRange)">
                      {{$t('buttons.ok')}}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-btn :loading="loading" color="primary" @click="formInvoice" :disabled="invalid" block>
              {{$t('buttons.generate-invoice')}}
            </v-btn>
          </v-form>
        </validation-observer>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
  import 'element-ui/lib/theme-chalk/index.css';
  import {Message} from "element-ui"
  import {AXIOS} from "../../../http-commons";
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';
  import {useAgencies} from "../../stores/agenciesStore";
  import {mapState} from "pinia";

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  let moment = require('moment');

  export default {
    name: "Invoicing",
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        locale: null,
        langName: null,
        loading: false,
        agencyId: this.$route.params.agencyId,
        agency: [],
        datePickerProps: {
          firstDayOfWeek: 1
        },
        dateRange: [],
        menu: false,
        modal: false,
        isPeriodRequired: false,
        invoiceLangs: [
          {langId: 1, langName: 'RU', langFullName: this.$t('invoicing-page.lang-ru')},
          {langId: 2, langName: 'UA', langFullName: this.$t('invoicing-page.lang-ua')},
          {langId: 3, langName: 'EN', langFullName: this.$t('invoicing-page.lang-en')},
        ],
        orders: [],
        totalPages: null,
        totalCost: null,
        totalSymbols: null,
        orderQuantity: null,
        curr: null
      }
    },
    methods: {
      updateNeededCheck() {
        if (!this.agencies.length) {
          const agencyStore = useAgencies()
          agencyStore.getAgencyList()
        }
      },
      formInvoice() {
        this.loading = true;
        let currDate = new Date().getFullYear() + "-" + ('0' + (new Date().getMonth() + 1)).slice(-2) + "-" + ('0' + new Date().getDate()).slice(-2)
        this.calculateTotals();
        if (this.orders.length < 1) {
          this.noDebtNotification();
        } else {
          const request = require('request');
          let templateId;
          const bearerToken = 'eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI0MTgxIiwiYXVkIjoiY2FyYm9uZSIsImV4cCI6MjI1MTI2NjY5NywiZGF0YSI6eyJpZEFjY291bnQiOjQxODF9fQ.AUZQPjwyQYJONGOMfmIySB2-B9_RmuhH4PBWHH6bjtjKeiV6b8DgpJCR00j2Cf7t_y6C5as73T1ubTD0sUZLqJl5AS6AXHYBpV9P7TkGdM1iux4fMvwMbgFdmnKG6CYIENZJ3AUiYlK50ng5kXHS7bC8ZZ2i2u1zEZwyc3XB3owii5N0'

          if (this.langName === 'UA') {
            templateId = 'ff508f3dff72d0f8c6c1abe5453c51195fa7aaaa809eb775a5fdac2e928c16b3';
            this.curr = 'ГРН'
          }
          if (this.langName === 'RU') {
            templateId = '4bc0d2c5cb49ed0634da6318686853d79a2cd656c89d20b1906dad1fc4bc6903';
            this.curr = 'ГРН'
          }
          if (this.langName === 'EN') {
            templateId = 'ab82b54474651216aea716ba5276f90f2cb7361aa27809716392f83fb7cda6a4';
            this.curr = 'UAH'
          }
          request.post({
            url: 'https://render.carbone.io/render/' + templateId,
            json: true,
            body: {
              convertTo: 'pdf',
              timezone: 'Europe/Kiev',
              complement: {
                "curr": this.curr,
                "totalPages": this.totalPages,
                "totalCost": this.totalCost,
                "totalSymbols": this.totalSymbols,
                "orderQuantity": this.orderQuantity
              },
              reportName: currDate + "_" + this.orders[0].agency.agencyName,
              data: this.orders
            },
            headers: {
              'Authorization': 'Bearer ' + bearerToken,
              'carbone-version': '4'
            }
          }, (err, response, body) => {
            // Handle error
            // Body contains an error or the render ID
            console.log("error: " + body.error);
            console.log("success: " + body.success)
            console.log("renderId: " + body.data.renderId)
            console.log("status_code: " + response.statusCode)
            // window.open('https://render.carbone.io/render/' + body.data.renderId, '_blank');
            window.location.href = "https://render.carbone.io/render/" + body.data.renderId;
            //update user's render counter
            let params = new URLSearchParams();
            params.append('userId', this.currentUser.id);
            AXIOS.put(`/api/updateCarboneRenders/`, params)
            this.loading = false;
          });
        }
      },
      calculateTotals() {
        this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
        let debtOrders = this.orders.filter(e => e.status.statusName !== 'Paid' && e.totalCost > 0);
        if (this.isPeriodRequired) {
          let startDate = new Date(this.dateRange[0]);
          let endDate = new Date(this.dateRange[1]);
          //when start date later than end date -> set dates visa versa
          if (startDate.getTime() > endDate.getTime()) {
            let tmp = endDate;
            endDate = startDate;
            startDate = tmp;
          }
          debtOrders = debtOrders.filter(e => new Date(e.orderDate).getTime() >= startDate.getTime() && new Date(e.orderDate).getTime() <= endDate.getTime());
        }
        this.orderQuantity = debtOrders.length;
        this.totalCost = debtOrders
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.totalSymbols = debtOrders
          .map((order) => order.symbolCount)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.totalPages = debtOrders
          .map((order) => order.pageCount)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        this.orders = debtOrders;
      },
      prepareInvoiceData() {
        this.loading = true;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('agencyId', this.agencyId);
        AXIOS.get('/api/getAgencyOrders', {params})
          .then(response => {
            this.orders = response.data;
            this.loading = false;
          })
      },
      noDebtNotification() {
        Message({
          message: this.$t('messages.no-debt'),
          type: 'error',
          duration: 3000
        });
        this.prepareInvoiceData()
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
      dateRangeText() {
        return this.dateRange.join(' ~ ')
      },
    },
    created() {
      this.updateNeededCheck();
      if (this.agencyId != null) {
        this.prepareInvoiceData();
      }
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
