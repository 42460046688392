var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('base-material-card',{attrs:{"color":"primary","icon":"mdi-receipt-text-check-outline"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.create($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.agencies,"label":_vm.$t('invoicing-page.agency'),"required":"","item-text":"agencyName","item-value":"agencyId","prepend-icon":"mdi-domain"},on:{"input":_vm.prepareInvoiceData},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.invoiceLangs,"label":_vm.$t('invoicing-page.language'),"required":"","item-text":"langFullName","item-value":"langName","prepend-icon":"mdi-translate"},model:{value:(_vm.langName),callback:function ($$v) {_vm.langName=$$v},expression:"langName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-switch',{attrs:{"inset":"","label":_vm.$t('invoicing-page.calculate-period')},model:{value:(_vm.isPeriodRequired),callback:function ($$v) {_vm.isPeriodRequired=$$v},expression:"isPeriodRequired"}})],1)],1),(_vm.isPeriodRequired)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dateRange,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dateRange=$event},"update:return-value":function($event){_vm.dateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('invoicing-page.select-invoicing-range'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on)),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}],null,true),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"first-day-of-week":_vm.datePickerProps.firstDayOfWeek,"scrollable":"","range":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.dateRange)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.ok'))+" ")])],1)],1)],1)],1):_vm._e(),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":invalid,"block":""},on:{"click":_vm.formInvoice}},[_vm._v(" "+_vm._s(_vm.$t('buttons.generate-invoice'))+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }